<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Novo Tipo de Concessionaria" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectTiposConcess || !selectTiposConcess.length" />
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <!-- Tabela de Tipos de concessionarias -->
        <DataTable ref="dt" :value="tipos_concess" v-model:selection="selectTiposConcess" dataKey="id" :paginator="true" :rows="50" :filters="filters" 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5, 10, 25]"
         currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)" responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gerenciar Tipos de Concessionarias</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:5rem;">
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="descricao" header="Descricao" :sortable="true" headerStyle="width:30%; min-width:8rem;">
            <template #body="slotProps">
              <span class="p-column-title">Descrição</span>
              {{ slotProps.data.descricao }}
            </template>
          </Column>
          
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="apagarTipoConcessModal(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
        
        <!-- Modal de edição de tipo de concess -->
        <Dialog v-model:visible="productDialog" :style="{ width: '450px' }" header="Editar Tipo de Concessionaria" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">Descrição</label>
            <InputText id="bome" v-model.trim="tipo_concess.descricao" required="true" autofocus :class="{ 'p-invalid': submitted && !tipo_concess.descricao }" />
            <small class="p-invalid" v-if="submitted && !tipo_concess.descricao">Razão Social é obrigatória.</small>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarTipoConcess" />
          </template>
        </Dialog>

        <!-- Modal deletar tipo de concess -->
        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="tipo_concess">Certeza que vai apagar: <b>{{ tipo_concess.descricao }}</b>?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm" @click=" deleteProductDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarTipoConcess" />
          </template>
        </Dialog>

        <!-- Modal deletar varios usuarios -->
        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="tipo_concess">Apagar Tipos de Concessionarias selecionados?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>

      </div>
    </div>
  </div>
</template>

<script>

  import { FilterMatchMode } from "primevue/api";

  export default {
    data() {
      return {
        tipos_concess: [],
        tipo_concess: {},
        selectTiposConcess: null,
        productDialog: false,
        deleteProductDialog: false,
        deleteProductsDialog: false,
        filters: {},
        submitted: false,
        
      };
    },

    created() {
      this.initFilters();
    },
    mounted() {
      // this.listarConcessionarias();
      // this.listarEstados();
      this.listarTiposConcessionarias();

    },


    methods: {
      // listar tipos de concessionarias
      listarTiposConcessionarias(){
        this.axios
          .post("/concessionarias/tipos/listar")
          .then((response) => {
            this.tipos_concess = response.data.dados;
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      },

      openNew() {
        this.tipo_concess = {};
        this.submitted = false;
        this.productDialog = true;
      },

      editProduct(tipo_concess) {
        this.productDialog = true;
        this.tipo_concess = { ...tipo_concess };
        // console.log(this.usuario)
      },


      hideDialog() {
        this.productDialog = false;
        this.submitted = false;
      },




      // criar/editar usuario
      salvarTipoConcess() {
        this.submitted = true;

        if (this.tipo_concess.id) {
          // Ediçao de concessionaria
          this.axios
            .post("concessionarias/tipos/editar", this.tipo_concess)
            .then(() => {

              // console.log(this.concessionaria)

              this.productDialog = false;
              this.tipo_concess = {};
              this.listarTiposConcessionarias();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        } else {
          // Cadastrar novo concessionaria
          this.axios
            .post("/concessionarias/tipos/adicionar", this.tipo_concess)
            .then(() => {

              // alert(response.data.data)

              this.productDialog = false;
              this.tipo_concess = {};
              this.listarTiposConcessionarias();
            })
            .catch(() => {
              self.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        }
      },

      apagarTipoConcess() {
        this.axios
        // console.log(this.concessionaria)
          .post("/concessionarias/tipos/apagar", this.tipo_concess)
          .then(() => {
            
            this.deleteProductDialog = false;
            this.concessionaria = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Tipo de concessionaria apagada com sucesso!",
              life: 3000,
            });
            this.listarTiposConcessionarias();
          })
          .catch(() => {
            
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      },

      apagarTipoConcessModal(tipo_concess) {
        this.tipo_concess = tipo_concess;
        this.deleteProductDialog = true;
      },

      // botao de excluir selecionados
      confirmDeleteSelected() {
        this.deleteProductsDialog = true;
      },

      deleteSelectedProducts() {
        this.tipos_concess = this.tipos_concess.filter(
          (val) => !this.selectTiposConcess.includes(val)
        );
 
        let obj = this.selectTiposConcess;
        Object.keys(obj).forEach((key) => {
          this.axios
            .post("/concessionarias/tipos/apagar", obj[key])
            .then(() => {
              this.deleteProductDialog = false;
              this.tipo_concess = {};
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Tipo de concessionaria apagada com sucesso!",
                life: 3000,
              });
              this.listarTiposConcessionarias();
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Ops!",
                detail: "Chamar o T.I.",
                life: 3000,
              });
            });
        });
 
        this.deleteProductsDialog = false;
        this.selectTiposConcess = null;
      },
     
     exportCSV() {
       this.$refs.dt.exportCSV();
      },
      
      
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
      
    },
  };


</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
